import React from "react";

const Home = () => (
    <>
        <div className="masterborder">
        <div id="middle">
            <div id="middletexts">
                <h1>Welcome to my website</h1>
                <p>This site shows some of the features that we can offer to<br />
                    various businesses. Every single business has a different
                    needs. <br />We have all the tools to digitalize your business.</p>
            </div>
        </div>
        </div>
    </>
);

export default Home;
