const More = () => (
    <>
        <div id="contact">
            <div id="contactcontents">
                    <div id="contacttop">
                    <h1>EMAIL ADDRESS:</h1>
                    <p>jan.mamyroian@gmail.com</p>
                    <h1>PHONE NUMBER:</h1>
                    <p>(438) 993-8015</p>
                    <hr />
                </div>
                <div id="contactbottom">
                    <h1>GitHub:</h1>
                    <p1 onClick={() => window.open("https://www.github.com/jano97/website")}>Website built with NodeJS</p1>
                    <p1 onClick={() => window.open("https://www.github.com/jano97/pyflask")}>Website built with Python Flask</p1>
                    <p1 onClick={() => window.open("https://www.github.com/jano97/react-node")}>Web application built with NodeJS and Reactjs</p1>
                </div>
            </div>
        </div>
    </>
)

export default More;