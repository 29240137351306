import React from "react";
import Shoplogo from "../branding/shoplogo.jpg";
import Logomockup from "../branding/logomockup.jpg";
import Webdev from "../branding/webdev.jpg";
import Flashdrive from "../branding/flashdrive.jpg";
import Newmoodboard from "../branding/newmoodboard.jpg";
import Packaging from "../branding/packaging.jpg";

const Branding = () => (
    <>
        <div id="brandingbody" className="masterborder">
            <div id="brandingtexts">
                <h1>Branding</h1>
                <div id="brandingfirstrow">
                    <img src={Shoplogo} alt="Shop logo" className="brandingfirst" />
                    <img src={Logomockup} alt="Logo on shop " className="brandingfirst" />
                </div>
                <h1>Promoting logos of various businesses and digitalizing them</h1>
                <div id="brandingsecondrow">
                    <img src={Webdev} alt="Macbook with logo" className="brandingsecond" />
                    <img src={Flashdrive} alt="Flashdrive with logo" className="brandingsecond" />
                </div>
                <h1>Creating Moodboards And Designing Packages</h1>
                <div id="brandingthirdrow">
                    <img src={Newmoodboard} alt="Moodboard" className="brandingsecond" />
                    <img src={Packaging} alt="packaging mockup" className="brandingthird" />
                </div>
            </div>
        </div>
    </>
);

export default Branding;