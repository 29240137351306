import React from "react";
import Beer from "../pictures/beer.jpg";
import Disk from "../pictures/Disk.jpg";
import Book1 from "../pictures/book_Page_1.jpg";
import Book2 from "../pictures/book_Page_2.jpg";
import Book3 from "../pictures/book_Page_3.jpg";
import Fixed1 from "../pictures/pictures-fixed_Page_1.jpg";
import Fixed2 from "../pictures/pictures-fixed_Page_2.jpg";
import Fixed3 from "../pictures/pictures-fixed_Page_3.jpg";
import Fixed4 from "../pictures/pictures-fixed_Page_4.jpg";
import Ugly from "../pictures/UGLY.jpg";
import Good from "../pictures/GOOD.jpg";
import Tree from "../pictures/treejpg.jpg";
import Tshirt from "../pictures/t-shirt.jpg";
import Poster from "../pictures/Poster.jpg";

const Works = () => (
    <>
   {/*MIDDLE SECTION*/}
    <div id="workspictures">
        {/*PACKAGING DESIGN*/}
        <h1 className="workstexts">Packaging designs</h1>
        <div className="pictures1">
            <img src={Beer} alt="Beer bottle" className="one"  />
            <img src={Disk} alt="Disk" className="one"  />
        </div>

        <hr className="worksline" />

        {/*DUST JACKET*/}
        <h1 className="workstexts">Dust jackets</h1>
        <div className="pictures2">
            <img src={Book1} alt="Dust jacket" className="two"  />
            <img src={Book2} alt="Dust jacket" className="two"  />
            <img src={Book3} alt="Dust jacket" className="two"  />
        </div>

        <hr className="worksline" />

        {/*FIXING PICTURES*/}
        <h1 className="workstexts">Fixing old pictures</h1>
        <div className="pictures3">
            <img src={Fixed1} alt="Fixed" className="three"  />
            <img src={Fixed2} alt="Fixed" className="three"  />
        </div>
        <div className="pictures4">
            <img src={Fixed3} alt="Fixed" className="four"  />
            <img src={Fixed4} alt="Fixed" className="four"  />
        </div>

        <hr className="worksline" />

        {/*}RETOUCHING PICTURES3*/}
        <h1 className="workstexts"> Retouching pictures</h1>
        <div className="pictures5">
            <img src={Ugly} alt="Before" className="five"  />
            <img src={Good} alt="After" className="five"  />
        </div>

        <hr className="worksline" />

        {/*POSTER GRAPHIC AND SHIRT DESIGN*/}
        <h1 className="workstexts"> Graphic designs</h1>
        <div className="pictures6">
            <img src={Tree} alt="Graphic design" className="six"  />
            <img src={Tshirt} alt="T-shirt design" className="six"  />
            <img src={Poster} alt="Poster design" className="six"  />
        </div>
        <hr className="worksline" />
    </div>
    </>
);

export default Works;