import React from "react";
import {ReactComponent as Pinterest} from "./pictures/pinterest-circle.svg";
import {ReactComponent as Linkedin} from "./pictures/linkedin.svg";
import {ReactComponent as Gmail} from "./pictures/gmail.svg";

const Footer = () => (
    <footer>
        <div id="topfooter">
            <div id="logos">
                <ul>
                    <li>
                        <button onClick={() => window.open("https://www.pinterest.ca/janmamyroian")}>
                            <Pinterest alt="Pinterest" className="footerlogo" />
                        </button>
                    </li>
                    <li>
                        <button onClick={() => window.open("https://www.linkedin.com/in/jan-mamyroian-b45088107/")}>
                            <Linkedin alt="Linkedin" className="footerlogo" />
                        </button>
                    </li>
                    <li>
                        <button onClick={() => window.open("https://mail.google.com/")}>
                            <Gmail alt="Gmail" className="footerlogo" />
                        </button>
                    </li>
                </ul>
            </div>
            <div id="line">
                <hr />
            </div>
        </div>
        <div id="bottomfooter">
            <div id="footerdiv">
                <h1>About this site:</h1>
                <p>Here you will find my works and some info about web development.</p>
            </div>
        </div>
    </footer>
);

export default Footer;

