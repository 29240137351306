import React from "react";
import { Link } from "react-router-dom";
import {ReactComponent as Logo} from "./pictures/DONE.svg";

const Header = () => (
    <header>
            <Logo alt="Logo" id="logo" />
            <div className="dropdown">
                <button className="dropbtn">MENU</button>
                <div id="headerhr">
                <nav className="dropdown-content">
                    <ul>
                        <li className="lilinks">
                            <Link to="/"  className="links">HOME</Link>
                        </li>
                        <li className="lilinks">
                            <Link to="works" className="links">WORKS</Link>
                        </li>
                        <li className="lilinks">
                            <Link to="branding" className="links">BRANDING</Link>
                        </li>
                        <li className="lilinks">
                            <Link to="faq" className="links">FAQ</Link>
                        </li>
                        <li className="lilinks">
                            <Link to="more" className="links">MORE</Link>
                        </li>
                    </ul>
                </nav>
                </div>
            </div>
    </header>
);

export default Header;