import "./css/master.css";
import { BrowserRouter as Router, Routes, Route, } from "react-router-dom";
import Home from "./pages/home";
import Works from "./pages/works";
import Branding from "./pages/branding";
import FAQ from "./pages/faq";
import More from "./pages/more";
import NotFoundPage from "./pages/NotFoundPage";
import Header from "./Header";
import Footer from "./Footer";

function App() {
  return (
      <div>
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route path="works" element={<Works />} />
            <Route path="branding" element={<Branding />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="more" element={<More />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
          <Footer />
        </Router>
    </div>
  );
}

export default App;
