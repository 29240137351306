const FAQ = () => (
    <>
    <div id="biggestfaq">
        <h1 id="faq">FAQ</h1>
        <div id="faqcontainer" className="masterborder">
            <div id="faqtexts">
                <p id="faqfirstp">Frequently Asked Questions</p>
                <h1>How much does it cost to build a website?</h1>
                <p>It will depend on the features you need.</p>
                <h1>Is there a monthly payments?</h1>
                <p>We charge a little fee to keep your website updated and make small changes.</p>
                <h1>What will be the content?</h1>
                <p>The content can be anything you want. We may make few suggestions for the improvement of the website.</p>
                <h1>What if I’m not sure what I want?</h1>
                <p>Many of our customers are not sure what their website should be. We take a look at your business and make suggestions accordingly.</p>
                <h1>Why should we choose this company for our website?</h1>
                <p>We are committed to our customers. We reply fast, and we update the
                    websites on time. We have the best price for the quality you choose.
                    we build the website within few days to ensure the satisfaction of the customer.</p>
            </div>
        </div>
        </div>
    </>
);

export default FAQ;
    